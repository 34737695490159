import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../elementsCursos/CursosWeb.css';
import LogoSoyBorten from '../../assets/LogoSoyBorten.png';
import '../../elementsCursos/Main.css';
import '../../elementsCursos/CursosNavbar.css';
import Header from '../../components/Cursos/Header';
import CoursesSection from '../../components/Cursos/CoursesSection';

const Component = () => {
    const navigate = useNavigate();

    const home = () => {
        navigate('/');
    };
    
    return (
        <div className="content-container">
            <div className="row">
                <nav id="CursosNavbar" className="CursosNavbar navbar bg-body-tertiary px-3 mb-3" >
                    <a className="navbar-brand d-flex align-items-center" style={{ marginLeft: '10vw' }}>
                        <img src={LogoSoyBorten} onClick={home} alt="Logo" width="100" height="100" className="logoimg d-inline-block align-top me-2" />
                    </a>
                    <ul className="nav nav-pills" style={{ marginRight: '5vw' }}>
                        <li className="nav-item">
                            <a className="nav-link" >TUGUETERWECREATE</a>
                        </li>
                    </ul>
                </nav>
                </div>
            <div className="text-image-container">
                <Header />
            </div>
            <CoursesSection />
        </div>
    );
};
export default Component;
