import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import '../../elements/ImageComponent.css';
import QuienSoyHD from '../../assets/QuienSoyHD.png';

const ImageComponent = () => {
    const typewriterOptions = {
        words: [
            'Todo es posible con la idea correcta, mi trabajo es ayudarte a encontrarla.',
            'Piensa creativo, comunica con impacto.'
        ],
    loop: 0,
    cursor: true,
    cursorStyle: '|',
    typeSpeed: 70,
    deleteSpeed: 50,
    delaySpeed: 2000
};

    return (
        <div className="container">
            <img src={QuienSoyHD} alt="Person" className="profile-image" />
                <div className="Principaltext-content">
                    <h1>
                    <Typewriter {...typewriterOptions} />
                    </h1>
                </div>
        </div>
    );
};

export default ImageComponent;