import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../elements/ColoresNavBar.css';
import Mentorias from '../components/SoyBorten/Mentorias';
import Servicios from '../components/SoyBorten/Servicios';
import Cursos from '../components/SoyBorten/Cursos';
import Contacto from '../components/SoyBorten/Contacto';
import Testimonios from '../components/SoyBorten/Testimonios';
import ImageComponent from '../components/SoyBorten/ImageComponent';
import AboutSection from '../components/SoyBorten/AboutSection';
import LogoSoyBorten from '../assets/LogoSoyBorten.png';

const SoyBorten = () => {
    const navigate = useNavigate();

    const handleNavClick = (item) => {
        if (item.navigateTo) {
            navigate(item.navigateTo); // Navega a la subpágina especificada.
        } else {
            const element = document.getElementById(item.id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' }); // Desplaza a la sección correspondiente.
            }
        }
    };

    const navItems = [
        { id: 'QuienSoy', label: 'QUIEN SOY' },
        { id: 'Mentorias', label: 'MENTORIAS' },
        { id: 'Servicios', label: 'SERVICIOS' },
        { id: 'Cursos', label: 'CURSOS' },
        { id: 'Contactame', label: 'CONTACTAME' },
        { id: 'Testimonios', label: 'TESTIMONIOS' },
        //{ id: 'TuguederWeCreate', label: 'TUGUEDERWECREATE', navigateTo: '/tugueder' }, // Agregado `navigateTo`.
    ];

    return (
        <div className="mainPage container-fluid">
            <div className="row">
                <nav id="PrincipalNavbar" className="PrincipalNavbar navbar bg-body-tertiary px-3 mb-3">
                    <a className="navbar-brand d-flex align-items-center" style={{ marginLeft: '10vw' }}>
                        <img src={LogoSoyBorten} alt="Logo" width="100" height="100" className="logoimg d-inline-block align-top me-2" />
                    </a>
                    <ul className="nav nav-pills" style={{ marginRight: '5vw' }}>
                        {navItems.map((item) => (
                            <li key={item.id} className="nav-item">
                                <a 
                                    className="nav-link" 
                                    onClick={() => handleNavClick(item)} // Maneja tanto scroll como navegación.
                                    style={{ cursor: 'pointer' }}
                                >
                                    {item.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>

                <ImageComponent className="no-margin-padding"/>
                <h4 id="QuienSoy" className="no-margin-padding"></h4>
                <AboutSection className="no-margin-padding"/>

                <h4 id="Mentorias" className="no-margin-padding"></h4>
                <Mentorias className="no-margin-padding"/>
                <h4 id="Servicios" className="no-margin-padding"></h4>
                <Servicios className="no-margin-padding"/>
                <h4 id="Cursos" className="no-margin-padding"></h4>
                <Cursos className="no-margin-padding"/>
                <h4 id="Contactame" className="no-margin-padding"></h4>
                <Contacto className="no-margin-padding"/>
                <h4 id="Testimonios" className="no-margin-padding"></h4>
                <Testimonios className="no-margin-padding"/>
            </div>
        </div>
    );
};

export default SoyBorten;
