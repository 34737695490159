

import React from 'react';
import CourseCard from '../../components/Cursos/CourseCard';
import Curso1A from '../../assets/Curso1A.jpg';
import Curso1B from '../../assets/Curso1B.jpg';
import '../../elementsCursos/CoursesSection.css';

const CoursesSection = () => {
    const courses = [
        { title: "Taller de Creación de Contenido y Comunicación en Redes Sociales: Grupo A", image: Curso1B, isNew: true },
        { title: "Taller de Creación de Contenido y Comunicación en Redes Sociales: Grupo B", image: Curso1A, isNew: true },
    ];

    return (
        <div className="courses-section">
            <h2>Mis cursos</h2>
            <div className="courses-container">
                {courses.map((course, index) => (
                    <CourseCard key={index} title={course.title} image={course.image} isNew={course.isNew} />
                ))}
            </div>
        </div>
    );
};

export default CoursesSection;