import React from 'react';
import '../../elementsTugueder/Navbar.css';

const Navbar = ({ logoSrc, navItems, onNavClick }) => {
    return (
        <nav className="navbarTugueder">
            <div className="navbarTugueder-top">
                {/* Logo */}
                <a href="#" className="navbarTugueder-logo">
                    <img src={logoSrc} alt="Logo" width="100" height="100" />
                </a>

                {/* Menú principal */}
                <ul className="navbarTugueder-menu">
                    {navItems.map((item) => (
                        <li key={item.id} className="navTugueder-item">
                            <a 
                                className="navTugueder-link" 
                                onClick={() => onNavClick(item)} // Pasas la lógica de click
                                style={{ cursor: 'pointer' }}
                            >
                                {item.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
