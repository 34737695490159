import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LogoSoyBorten from '../assets/LogoSoyBorten.png';
import ImageComponent from '../components/TuguederWeCreate/ImageComponent';
import ImageComponentSecondary from '../components/TuguederWeCreate/ImageComponentSecondary';
import Navbar from '../components/TuguederWeCreate/Navbar';

const TuguederWeCreate = () => {
    const navigate = useNavigate();

    const handleNavClick = (item) => {
        if (item.navigateTo) {
            navigate(item.navigateTo); // Navega a la subpágina especificada.
        } else {
            const element = document.getElementById(item.id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' }); // Desplaza a la sección correspondiente.
            }
        }
    };

    const navItems = [
        { id: 'QuienesSomos', label: 'QUIENES SOMOS' },
        { id: 'Soluciones', label: 'SOLUCIONES' },
        { id: 'Contactanos', label: 'CONTACTANOS' },
        { id: 'Equipo', label: 'NUESTRO EQUIPO' },
        { id: 'Colaboradores', label: 'NUESTRO COLABORADORES' }
    ];

    return (
        <div className="mainPage container-fluid">
            {/* Componente Navbar */}
            <Navbar
                logoSrc={LogoSoyBorten} // Pasas el logo al Navbar
                navItems={navItems} // Pasas los ítems del menú al Navbar
                onNavClick={handleNavClick} // Manejas la lógica de clics
            />

            {/* Contenido principal */}
            <div className="row">
                <ImageComponent className="no-margin-padding" />
                <h4 id="QuienSoy" className="no-margin-padding"></h4>
            </div>
            {/* Frase */}
            <div className="row">
                <ImageComponentSecondary className="no-margin-padding" />
                <h4 id="QuienSoy" className="no-margin-padding"></h4>
            </div>
        </div>
    );
};

export default TuguederWeCreate;
