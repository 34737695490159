import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import '../../elements/AboutSection.css';
import Comodin from '../../assets/Comodin.JPG';

const AboutSection = () => {
    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="about-container">
            <div className="text-section">
                <h1>HOLA MI NOMBRE ES BORTEN</h1>
                <h3>
                    Y SOY{':'}
                    <span>
                        <Typewriter
                            words={['ASESOR CREATIVO.', 'CREADOR DE CONTENIDO.', 'EMPRENDEDOR.', 'SPEAKER.']}
                            loop={0} // 0 for infinite loop
                            cursor
                            cursorStyle="|"
                            typeSpeed={70}
                            deleteSpeed={50}
                            delaySpeed={2000}
                        />
                    </span>
                </h3>
                <p className="intro-text">
                    Mi trayectoria no ha sido un cuento de éxito convencional; empecé en trabajos de alta rotación, desde vendedor puerta a puerta hasta cocinero, mientras me formaba profesionalmente. A pesar de la inestabilidad, siempre busqué mejorar lo que encontraba, innovando en procesos y ventas con un enfoque creativo y único.
                    Al principio, mis métodos se veían como rebeldía, pero en cada lugar que trabajaba dejaba un impacto positivo, optimizando procesos y aumentando la eficiencia. Sin embargo, después de repetidos ciclos de “vacaciones permanentes”, decidí seguir mi propio camino, donde la creatividad y la resolución de problemas se convirtieron en mi estilo de vida.
                </p>
                <p className='experience-text'>Hoy, me dedico a apoyar a Pymes y marcas personales, a optimizar su comunicación y crear experiencias memorables. A través de consultorías y talleres, enseño a emprendedores a encontrar su voz, compartiendo mis aprendizajes para que otros puedan construir su propio éxito, tal como yo lo he hecho.</p>
                <p className="experience-text">Por esto te pregunto...</p>
                <p className="experience-text">¿Quieres transformar tu emprendimiento y conectar auténticamente con tu audiencia? ¡Hablemos y trabajemos juntos para hacer crecer tu marca!</p>
                <button className="about-button" onClick={() => scrollToSection('Contactame')}>¡Hablemos!</button>
            </div>

            <img src={Comodin} alt="ComodinIMG" className="Abprofile-image" />
        </div>
    );
};

export default AboutSection;