import React from 'react';
import '../../elements/Mentorias.css';

const Mentorias = () => {
    const mentoriaCards = [
        {
            title: 'EMPRENDEDORES Y MARCAS PERSONALES',
            description: 'A clarificar tu mensaje y crear contenido auténtico que conecte con tu audiencia y destaque tu marca.'
        },
        {
            title: 'NEGOCIOS LOCALES ORIENTADOS A SERVICIOS',
            description: 'A optimizar la experiencia de sus clientes, mejorar su propuesta de valor y destacarse en un mercado saturado a través de estrategias creativas y auténticas.'
        },
        {
            title: 'TODO AQUEL QUE',
            description: 'Quiera romper barreras, revitalizar su contenido y comunicación e inyectar nuevas perspectivas e innovación a su marca.'
        }
    ];

    return (
        <div className="Mentorycontainer">
            <h1 className="h1Mentory">ESTOY AQUI PARA</h1>
            <h2 className="h2Mentory">APOYAR A:</h2>
            <div className="Mentory-Container">
                {mentoriaCards.map((card, index) => (
                    <div key={index} className="Mentorycard">
                        <h3>{card.title}</h3>
                        <p>{card.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Mentorias;