import React from 'react';
import PropTypes from 'prop-types';
import '../../elementsTugueder/ImageComponent.css';
import GymBuddy from '../../assets/GymBuddy.mp4'; // Asegúrate de que el video exista en esta ruta

const ImageComponent = () => {
    return (
        <div className="containerTugueder" data-testid="image-component-Tugueder">
            {/* Video de fondo */}
            <video
                className="background-video"
                src={GymBuddy}
                autoPlay
                loop
                muted
                playsInline
            >
                Tu navegador no soporta la reproducción de video.
            </video>
            
            {/* Contenido superpuesto */}
            <div className="overlayTugueder" aria-label="overlayTugueder-content">
                <h1 data-testid="title">Juntos para lograrlo</h1>
            </div>
        </div>
    );
};

ImageComponent.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    loading: PropTypes.string,
};

export default ImageComponent;
