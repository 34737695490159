import React from 'react';
import '../../elementsCursos/Header.css';
import { useNavigate } from 'react-router-dom';
import LogoSoyBorten from '../../assets/LogoSoyBorten.png';

const Header = () => {
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    };

    return (
        <div className="header-content">
            <div className="text-button-container">
                <h1>Trabajá de lo que te apasiona monetizando tus conocimientos 💻</h1>
                <p>
                    Los Community manager y social media manager son los perfiles más solicitados por
                    empresas y negocios a la hora de posicionar las redes sociales. Por eso, nuestros cursos,
                    te van a dar todas las herramientas y conocimientos que necesitás para poder convertirte en
                    uno de los perfiles más buscados y mejor pago.
                </p>
                <p>
                    Si todavía estás en relación de dependencia padeciendo el tener un jefe que controla tu
                    horario de entrada y salida, vacaciones limitadas, sueldos bajos y con aumentos que no
                    acompañan, nuestros cursos pueden ayudarte a abrir un nuevo camino profesional y
                    personal.
                </p>
                <button onClick={handleHome} className="home-button">Ir a Inicio</button>
            </div>
            <div className="image-container">
                <img src={LogoSoyBorten} alt="Instructor mostrando una computadora" />
            </div>
        </div>
    );
};

export default Header;