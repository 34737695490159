import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SoyBorten from './pages/SoyBorten';
import CursosWeb from './pages/Cursos/CursosWeb';
import CursoTallerConteRedes from './pages/Cursos/CursoTallerConteRedes';
import TuguederWeCreate from './pages/TugueterWeCreate';
import './elements/Responsive.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SoyBorten />} />
        <Route path="/cursos-web" element={<CursosWeb />} />
        <Route path="/curso-taller-conte-redes" element={<CursoTallerConteRedes />} />
        <Route path="/tugueder" element={<TuguederWeCreate />} />
      </Routes>
    </Router>
  );
}

export default App;
