import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../elements/Cursos.css';
import Curso1 from '../../assets/Curso1.jpg';

const Cursos = () => {
    return (
        <>
            <div className="Cursos-header">
                <p>Explora Nuestros Cursos</p>
                <p>
                    Descubre una amplia variedad de cursos diseñados para ayudarte a alcanzar tus metas profesionales y personales. 
                    En nuestra plataforma encontrarás talleres prácticos y contenido especializado que abarca desde marketing digital 
                    hasta habilidades técnicas. Únete y potencia tu conocimiento hoy mismo.
                </p>
            </div>
            <div className="Cursos-Container">
                <div className="Cursos-card">
                    <h2>Nuevo Curso ➔</h2>
                    <h3>Taller de Creación de contenido y comunicación en redes sociales</h3>
                    <img src={Curso1} className="card-img-Cursos" alt="Community Manager" />
                    <div id="bmc-container" style={{ marginTop: '20px', textAlign: 'center' }}>
                <a href="https://buymeacoffee.com/soyborten/extras" target="_blank" rel="noopener noreferrer">
                    <button style={{
                        backgroundColor: '#6A8D73',
                        border: 'none',
                        padding: '10px 20px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}>
                        Consigue Tu Cupo
                    </button>
                </a>
            </div>
                </div>
            </div>
            {/* Botón Buy Me a Coffee usando HTML directamente */}
        </>
    );
};

export default Cursos;