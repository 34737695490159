import '../../elements/Testimonios.css';
import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '700px',
    },
};

const Testimonios = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videos, setVideos] = useState([
        { id: "n2CTFKZRNuY" },
        { id: "Z_UaFiSwOQc"},
        { id: "-iuVqv_xWOU"},
        { id: "qX8nlBu1EZ4"},
        { id: "OBEFAR6fXuY"},
    ]);
    const carouselRef = useRef(null);

    const openModal = (id) => {
        setVideoUrl(`https://www.youtube.com/embed/${id}`);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setVideoUrl(''); 
    };

    const scrollLeft = () => {
        if (carouselRef.current) {
            if (carouselRef.current.scrollLeft === 0) {
                carouselRef.current.scrollLeft = carouselRef.current.scrollWidth;
            } else {
                carouselRef.current.scrollBy({
                    left: -carouselRef.current.offsetWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            if (carouselRef.current.scrollLeft + carouselRef.current.offsetWidth >= carouselRef.current.scrollWidth) {
                carouselRef.current.scrollLeft = 0;
            } else {
                carouselRef.current.scrollBy({
                    left: carouselRef.current.offsetWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (carouselRef.current) {
                carouselRef.current.scrollLeft = 0;
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="testimonials-carousel">
            <h2>Testimonios</h2>
            <div className="carousel-container">
                <button className="carousel-control left" onClick={scrollLeft}>❮</button>
                <div className="video-grid" ref={carouselRef}>
                    {videos.map((video, index) => (
                        <div key={index} className="video-thumbnail" onClick={() => openModal(video.id)}>
                            <img src={`https://img.youtube.com/vi/${video.id}/hqdefault.jpg`} alt={video.title} />
                            <div className="play-button">▶</div>
                            <p>{video.title}</p>
                        </div>
                    ))}
                </div>
                <button className="carousel-control right" onClick={scrollRight}>❯</button>
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Testimonio Video">
                <button onClick={closeModal} className="close-button">X</button>
                {videoUrl && (
                    <iframe
                        width="100%"
                        height="400"
                        src={videoUrl}
                        title="Testimonio Video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                )}
            </Modal>
        </div>
    );
};

export default Testimonios;