import React from 'react';
import PropTypes from 'prop-types';
import '../../elementsCursos/CourseCard.css';

const CourseCard = ({ title, image, isNew }) => {
    return (
        <div className="course-card">
            {isNew && <div className="new-badge">Nuevo</div>}
            <h3 className="course-title">{title}</h3>
            <img src={image} alt={title} className="course-image" />
            <button className="course-button">Quiero mi curso</button>
        </div>
    );
};

CourseCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    isNew: PropTypes.bool,
};

CourseCard.defaultProps = {
    isNew: false,
};

export default CourseCard;