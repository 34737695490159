import React, { useState } from 'react';
import '../../elements/Contacto.css';

const Contacto = () => {
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [rubro, setRubro] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [privacidad, setPrivacidad] = useState(false);
    const [envioExitoso, setEnvioExitoso] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validaciones simples
        if (!nombre || !email || !mensaje) {
            setError('Por favor, completa todos los campos obligatorios.');
            return;
        }

        const datos = { nombre, telefono, email, rubro, mensaje };

        try {
            const response = await fetch('https://www.soyborten.com/api/contacto', { // URL corregida
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(datos),
            });

            if (response.ok) {
                setEnvioExitoso(true);
                setError(null);
                setNombre('');
                setTelefono('');
                setEmail('');
                setRubro('');
                setMensaje('');
            } else {
                throw new Error('Error en el envío del formulario');
            }
        } catch (error) {
            setError('Hubo un problema al enviar el mensaje. Inténtalo más tarde.');
            setEnvioExitoso(false);
        }
    };

    return (
        <div style={{ backgroundColor: '#E9EED9', padding: '3rem', color: '#333' }}>
            <h2 className="text-center text-primary">¿Charlamos?</h2>
            <div className="contact-container mt-4">
                <div className="row">
                    <div className="col-md-6">
                        <p>Puedes escribirme</p>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre y apellidos *"
                                    value={nombre}
                                    onChange={(e) => setNombre(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="Teléfono"
                                    value={telefono}
                                    onChange={(e) => setTelefono(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email *"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Rubro"
                                    value={rubro}
                                    onChange={(e) => setRubro(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    placeholder="Cuéntame *"
                                    rows="3"
                                    value={mensaje}
                                    onChange={(e) => setMensaje(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                ENVIAR
                            </button>
                        </form>

                        {envioExitoso && <p className="text-success mt-3">¡Mensaje enviado exitosamente!</p>}
                        {error && <p className="text-danger mt-3">{error}</p>}
                    </div>

                    <div className="col-md-6 d-flex flex-column align-items-start">
                        <p>O ponte directamente en contacto conmigo</p>
                        <p>
                            <span className="text-primary">📞</span> +569 92086090
                        </p>
                        <p>
                            <span className="text-primary">✉️</span> borten@soyborten.com
                        </p>
                        <p>
                            <span className="text-primary">🔗</span>{' '}
                            <a
                                href="https://www.linkedin.com/in/alfonso-adriani-borten-a23b01109/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'blue' }}
                            >
                                www.linkedin.com/in/alfonso-adriani-borten
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacto;
