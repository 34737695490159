import React from 'react';
import PropTypes from 'prop-types';
import '../../elementsTugueder/ImageComponentSecondary.css';

const ImageComponentSecondary = () => {

    return (
        <div className="containerTuguederSecondary" data-testid="image-component-TuguederSecondary">
            <div className="overlayTuguederSecondary">
                <h1>ImageComponentSecondary</h1>
            </div>
        </div>
    );
};

ImageComponentSecondary.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    loading: PropTypes.string,
};

export default ImageComponentSecondary;
