import React from 'react';
import '../../elementsCursos/CursoTallerConteRedes.css';
import Curso1 from '../../assets/Curso1.jpg';

const CourseDetails = () => {
    return (
        <div className="course-container">
            <div className="course-info">
                <h1>Sketching diario como inspiración creativa</h1>
                <p>Un curso de <strong>Sorie Kim</strong>, Ilustradora y docente</p>
                <div className="course-meta">
                    <span className="badge">TOP VENTAS</span>
                    <span>98% valoraciones positivas (3.9K)</span>
                    <span>155.800 estudiantes</span>
                    <span>Audio: Inglés, Turco</span>
                    <span>Inglés · Español · Portugués · Alemán · Francés · Italiano · Polaco · Neerlandés</span>
                </div>
                <nav className="course-tabs">
                    <a href="#presentation">Presentación</a>
                    <a href="#contents">Contenidos</a>
                    <a href="#community">Comunidad</a>
                    <a href="#projects">Proyectos</a>
                </nav>
                <div className="course-video">
                    <img src={Curso1} alt="Curso" className="course-image" />
                </div>
                <div className="course-description">
                    <h2>Explora, imagina y experimenta en tu sketchbook cada día para mejorar tu proceso de dibujo</h2>
                    <p>Ejercitar la imaginación a diario mejora el proceso creativo y, además, es una excelente forma de perfeccionar tu técnica de dibujo...</p>
                </div>
            </div>
            <aside className="course-sidebar">
                <div className="price-box">
                    <span className="price">$845,00</span>
                    <span className="discount">92% Dto. <span className="original-price">$10.890 CLP</span></span>
                    <button className="buy-button">Comprar</button>
                    <p className="offer-timer">La oferta termina en 02h : 59m : 36s</p>
                </div>
                <div className="regular-price">
                    <h3>Precio regular</h3>
                    <span>$10.890 CLP</span>
                    <p>Pagar precio completo</p>
                </div>
                <div className="additional-info">
                    <p>98% valoraciones positivas (3.9K)</p>
                    <p>155.800 estudiantes</p>
                    <p>15 lecciones (2h 25m)</p>
                    <p>15 recursos adicionales (5 archivos)</p>
                    <p>Online a tu ritmo</p>
                    <p>Disponible en la app</p>
                    <p>Audio: Inglés, Turco</p>
                    <p>Nivel: Iniciación</p>
                    <p>Acceso ilimitado para siempre</p>
                </div>
                <button className="gift-button">Comprar para regalo</button>
            </aside>
        </div>
    );
};

export default CourseDetails;