import React from 'react';
import '../../elements/Servicios.css';
import LogoAsesorias from '../../assets/LogoAsesorias.png';
import Logo1A1 from '../../assets/Logo1A1.png';

const serviciosData = [
{
    id: 1,
    logo: LogoAsesorias,
    title: 'Asesorías Personalizadas',
    alt: 'Asesorias Personalizadas',
},
{
    id: 2,
    logo: Logo1A1,
    title: 'Asesorías 1:1',
    alt: 'Asesorías 1:1 para emprendedores',
},
{
    id: 3,
    logo: LogoAsesorias,
    title: 'Mentorías para Community Managers',
    alt: 'Mentorías para Community Managers',
},
{
    id: 4,
    logo: Logo1A1,
    title: 'Fotografía para redes sociales',
    alt: 'Fotografía para redes sociales',
},
];

const Servicios = () => {
    return (
    <>
        <div className="Servicios-header">
            <p>Mis Servicios</p>
        </div>
        <div className="Servicios-Container">
            {serviciosData.map((servicio) => (
                <div key={servicio.id} className="Service-card">
                    <img src={servicio.logo} className="card-img-Servicios" alt={servicio.alt} />
                    <h3>{servicio.title}</h3>
                </div>
            ))}
        </div>
    </>
    );
};

export default Servicios;